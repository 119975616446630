import React from 'react';
import { LayoutComponents as Layout } from '_components';
import {
    RouteComponentProps,
    // , Redirect
} from '@reach/router';
// import { useIsUserLogged } from '_hooks';
// import { webPaths } from '_constants';

type Props = RouteComponentProps & {
    children: React.ReactNode;
    className?: string;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function AppWindow({ children, className }: Props) {
    // const userLogged = useIsUserLogged();

    // if (!userLogged) return <Redirect to={webPaths.LOGIN} noThrow />;
    return (
        <Layout.PageWrap className={className}>
            <Layout.AppBar />
            {children}
            {/* <Layout.Footer smallMargin /> */}
        </Layout.PageWrap>
    );
}

export default AppWindow;
