// import { RegistrationResponse } from './../user/types';
// Example for using discriminated union types.
export type ThemeColors = 'light' | 'dark';
export type AvailableLanguages = 'en' | 'cs' | 'de' | 'fr' | '';

// Use enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define however naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the convention
// of Redux's `@@INIT` action.
// eslint-disable-next-line import/prefer-default-export
export enum LayoutActionTypes {
    SET_THEME = '@LAYOUT/set_theme',
    SW_INIT = '@LAYOUT/service_worker_init',
    SW_UPDATE = '@LAYOUT/service_worker_update',
    NOTIFICATION_ADD = '@LAYOUT/notification_add',
    NOTIFICATION_REMOVE = '@LAYOUT/notification_remove',
    NOTIFICATIONS_REMOVE_ALL = '@LAYOUT/notification_remova_all',
    SET_LANGUAGE = '@LAYOUT/set_language',
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface LayoutState {
    theme: ThemeColors;
    serviceWorkerRegistration: ServiceWorkerRegistration | null;
    notifications: string[];
    language?: AvailableLanguages;
}
