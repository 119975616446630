import React from 'react';
import { CardContent, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';
import { Card } from '_components';
import c from 'classnames';

const useStyles = makeStyles(({ palette }: Theme) =>
    createStyles({
        text: { fontWeight: 700, color: palette.primary.main, fontSize: '2.5rem' },
        textSmaller: { fontSize: '1.5rem' },
        cardContent: { justifyContent: 'center', display: 'flex' },
    }),
);

function isValue<T extends string | number>(val: T | unknown): val is T {
    return typeof val === 'number' || typeof val === 'string';
}

type Props = {
    title: string;
    text?: string | number;
    textSmaller?: boolean;
};

function ResultsLoading(props: Props) {
    const { title, text, textSmaller } = props;
    const classes = useStyles();

    return isValue(text) ? (
        <Card title={title}>
            <CardContent className={classes.cardContent}>
                <Typography className={c(classes.text, textSmaller && classes.textSmaller)} variant="body2">
                    {text}
                </Typography>
            </CardContent>
        </Card>
    ) : null;
}

export default ResultsLoading;
