import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

function initSentry() {
    Sentry.init({
        dsn: 'https://815fd4d35dff4a38b9b484ac0800be58@o469442.ingest.sentry.io/4504015734308864',
        integrations: [new BrowserTracing()],

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
}

// eslint-disable-next-line import/prefer-default-export
export { initSentry };
