import reactQueryClient from './client';

export type AnalyseResult = {
    planogram_compliance: number;
    skus_to_add: string[];
    skus_to_rearrange: string[];
    detection_count: number;
    misplaced_skus: string[];
    skus_to_remove: string[];
    analyseResult: string;
};

export enum Status {
    NEW = 'NEW',
    new = 'new',
    processing = 'processing',
    completed = 'completed',
    noDetection = 'noDetection',
    failed = 'failed',
}

type Session = {
    image_original: string;
    created: string; // '2022-10-19T01:53:18.271574'
    id: string; // '2022-10-19_0153_de62587c-adb8-4f18-8e7a-a4e840ab5de8'
    photo_type: 'energy' | string;
    planogram_unique_code: null;
    updated: string;
    status: Status;
    blob_base: string;
    results?: AnalyseResult;
    photos_to_display?: string[];
    // recognized_units?: null | string;
};

export type ResultOk = Session & {
    result: 'OK';
};
export type ResultError = {
    result: 'ERROR';
    message?: string;
};

type QueryKey = (string | undefined)[];

// export function getResultQuery = useQuery([get])

// eslint-disable-next-line import/prefer-default-export
export { reactQueryClient };
