import React from 'react';
import { RouteComponentProps, Link as ReachLink } from '@reach/router';
import {
    createStyles,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@material-ui/core';
import { LayoutComponents as Layout } from '_components';
import dayjs from 'dayjs';
import { FormattedMessage } from 'react-intl';
import { useSelector } from 'react-redux';
import { ApplicationState } from '_store';
import { webPaths } from '_constants';

const useStyles = makeStyles(() =>
    createStyles({
        tableContainer: { maxWidth: '550px', alignSelf: 'center' },
        row: { textDecoration: 'none' },
    }),
);

type Props = RouteComponentProps & {};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function ResultsList(props: Props) {
    const classes = useStyles();

    const results = useSelector((state: ApplicationState) => state.results);
    const sessionList = Object.values(results.sessions)
        .map(res => ({ ...res, created: dayjs.utc(res.created) }))
        .sort((a, b) => (a.created.isAfter(b.created) ? -1 : 1));

    return (
        <Layout.Main>
            <TableContainer className={classes.tableContainer} component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <FormattedMessage id="resultsList.table.created" />
                            </TableCell>
                            <TableCell align="right">
                                <FormattedMessage id="resultsList.table.status" />
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {sessionList.map(row => (
                            <TableRow
                                className={classes.row}
                                component={ReachLink}
                                to={`${webPaths.RESULTS}/${row.id}`}
                                key={row.id}
                                hover
                            >
                                <TableCell>{row.created.format('lll')}</TableCell>
                                <TableCell align="right">{row.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Layout.Main>
    );
}

export default ResultsList;
