import Lockr from 'lockr';
import { LS_KEYS } from '_constants';

export const isDev = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const isDebugOn = () => isDev() || !!Lockr.get(LS_KEYS.DEBUG);

function setupDebugLog() {
    if (isDebugOn()) {
        return {
            /* eslint-disable no-console */
            log: (...messages: Array<string>) => {
                console.log(...messages);
            },
            error(...errors: Array<string>) {
                console.error(...errors);
            },
            warn(...warnings: Array<string>) {
                console.warn(...warnings);
            },
            /* eslint-enable no-console */
        };
    }
    return {
        /* eslint-disable @typescript-eslint/no-unused-vars */
        log: (..._: Array<string>) => {},
        error: (..._: Array<string>) => {},
        warn: (..._: Array<string>) => {},
        /* eslint-enable @typescript-eslint/no-unused-vars */
    };
}

export const debugLog = setupDebugLog();

// eslint-disable-next-line import/prefer-default-export
