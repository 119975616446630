export interface Profile {
    fullName?: string;
    // phoneNumber?: string;
    // created?: string;
    // language?: string;
    // retailers?: Array<{ id: number; name: string }>;
    // brandOwners?: Array<{ id: number; name: string; short_name: string }>;
    expiration?: number;
}

// export interface RegistrationResponse {
//     phone_number: string;
// }

// eslint-disable-next-line import/prefer-default-export
export enum UserActionTypes {
    // FETCH_LOADING = '@USER/fetch_LOADING',
    // FETCH_SUCCESS = '@USER/fetch_SUCCESS',
    // FETCH_ERROR = '@USER/fetch_ERROR',
    REMOVE_DATA = '@USER/remove_user_data',
    UPDATE_DATA = '@USER/update_user_data',
    // LOGIN_FAIL = '@USER/login_FAIL',
    // REGISTER_OK = '@USER/register_OK',
    // REGISTER_FAIL = '@USER/register_FAIL',
    SET_LANGUAGE = '@USER/set_language',
}

export type UserState = Profile;

// export interface UserState {
//     readonly loading: boolean;
//     readonly language: string;
//     readonly profile: Profile;
//     readonly error?: AxiosResponse;
//     readonly regResponse?: RegistrationResponse;
//     readonly regError?: AxiosResponse;
// }
