import React from 'react';
import { Box, Button, createStyles, makeStyles, Paper, Theme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import {
    CachedOutlined as CachedIcon,
    DeleteForeverOutlined as DeleteForeverIcon,
    ErrorOutlineOutlined as ErrorIcon,
    CameraAltOutlined as CameraIcon,
} from '@material-ui/icons';
// local imports
import { Camera, Text } from '_components';
import { AxiosError } from 'axios';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        code: {
            marginTop: theme.spacing(2),
            paddingLeft: theme.spacing(1.5),
            paddingRight: theme.spacing(3),
            paddingBlock: theme.spacing(2),
            width: '500px',
            maxWidth: '100%',
            wordBreak: 'break-all',
            whiteSpace: 'pre-wrap',
            color: theme.palette.grey[800],
        },
        buttonsWrap: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap-reverse',
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'inherit',
                '& > *': {
                    marginRight: theme.spacing(3),
                    marginBottom: theme.spacing(3),
                },
            },
        },
        buttonStyle: {
            width: 'fit-content !important',
            [theme.breakpoints.down('sm')]: { width: '100% !important' },
        },
    }),
);

type Props = {
    onPhotoTaken: (photo: File) => void;
    onReupload: () => void;
    error?: AxiosError | null;
    loading?: boolean;
};

function PlanogramSelectActions(props: Props) {
    const { error, onPhotoTaken, loading, onReupload } = props;
    const cls = useStyles();
    const errIntlSfx = error ? '.error' : '';

    return (
        <Box hidden={loading}>
            <Box display="flex" alignItems="center">
                {error && (
                    <>
                        <ErrorIcon color="error" />
                        &ensp;
                    </>
                )}
                <Text color={error ? 'error' : undefined} myVariant="desc">
                    <FormattedMessage id={`selectPlanogram.description${errIntlSfx}`} />
                </Text>
            </Box>
            {error && (
                <Box display="flex" alignItems="flex-start" flexDirection="column">
                    <Paper className={cls.code} elevation={0}>
                        <code>
                            {error?.message}
                            {'\n'}
                            {error?.response?.data}
                        </code>
                    </Paper>
                </Box>
            )}
            <Box className={cls.buttonsWrap} mt={4}>
                <Camera onPhotoTaken={onPhotoTaken}>
                    <Button
                        startIcon={!error ? <CameraIcon /> : <DeleteForeverIcon />}
                        variant={error ? 'outlined' : undefined}
                        className={cls.buttonStyle}
                    >
                        <FormattedMessage id={`selectPlanogram.action.button${errIntlSfx}`} />
                    </Button>
                </Camera>
                {error && (
                    <Button onClick={() => onReupload()} startIcon={<CachedIcon />}>
                        <FormattedMessage id="takePhoto.reupload.button" />
                    </Button>
                )}
            </Box>
        </Box>
    );
}

export default PlanogramSelectActions;
