import { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getResultFetch } from '_query/requests';
import { useDispatch, useSelector } from 'react-redux';
import { Status } from '_query';
import { resultsUpdateSession } from '_store/results';
import { ApplicationState } from '_store';
import { debugLog } from '_utils';
import dayjs from 'dayjs';

type UseResultsRefetchOptions = {
    refetchInterval?: number;
    processingTimeoutSecs?: number;
};

const OPTIONS_DEFAULT = { refetchInterval: 5_000, processingTimeoutSecs: 240 };

type StatesFinished = Status.noDetection | Status.completed | Status.failed;
type StatesDone = StatesFinished | 'timeout' | 'loadingError';
type StatesDoneType = StatesDone | undefined;
const STATES_FINISHED: StatesFinished[] = [Status.noDetection, Status.completed, Status.failed];
function isStatusFinished(status: StatesFinished | Status): status is StatesFinished {
    return STATES_FINISHED.includes(status as StatesFinished);
}

// const STATUS_FINISHED: StatesDone[] = [Status.noDetection, Status.completed];

const useResultsRefetch = (
    sessionId: string | undefined,
    options?: UseResultsRefetchOptions,
): [StatesDoneType, { isLoading: boolean; loadingError: any }] => {
    // keeping it's own state so @doneReason can be avoided
    const mergedOptions = options ? { ...OPTIONS_DEFAULT, ...options } : OPTIONS_DEFAULT;
    // const processingTimeoutSecs = mergedOptions?.processingTimeoutSecs ||
    const { processingTimeoutSecs, refetchInterval } = mergedOptions;
    const [_doneReason, _setDoneReason] = useState<StatesDoneType>();

    const dispatch = useDispatch();
    const results = useSelector((state: ApplicationState) => state.results);
    const session = sessionId ? results.sessions[sessionId] : undefined;

    const sessionStatus = session?.status;
    const sessionUpdatedStr = session?.updated;
    const timeoutMoment = useMemo(
        () => sessionUpdatedStr && dayjs.utc(sessionUpdatedStr).add(processingTimeoutSecs, 'seconds'),
        [sessionUpdatedStr, processingTimeoutSecs],
    );

    // useQuery
    const { refetch, isLoading, error: loadingError, isFetching } = useQuery(
        [getResultFetch.name, sessionId],
        getResultFetch,
        {
            onSuccess: data => {
                dispatch(resultsUpdateSession(data.id, data));
            },
            onError: () => _setDoneReason('loadingError'),
            retryDelay: 2000,
            retry: 2,
            enabled: true,
        },
    );

    // Checks for "finished statuses" and set it as the reason
    useEffect(() => {
        if (sessionStatus && isStatusFinished(sessionStatus)) _setDoneReason(sessionStatus);
    }, [sessionStatus, _doneReason]); // @_doneReason is here to give priority to isStatusFinished one's

    // Checks if isn't too long from UPDATED datetime prop
    useEffect(() => {
        if (!timeoutMoment || _doneReason) return () => { };

        const getTimeoutDiff = () => timeoutMoment.diff(dayjs());
        const checkTimeout = () => {
            const timeDiff = getTimeoutDiff();
            if (timeDiff <= 0) {
                _setDoneReason('timeout');
            }
            return timeDiff;
        };

        const timeDiff = getTimeoutDiff();
        const timeoutId = setTimeout(checkTimeout, timeDiff + 500);

        return () => {
            clearTimeout(timeoutId);
        };
    }, [timeoutMoment, _doneReason]);

    // Sets Refetching loop until `!finishedReason === true`
    useEffect(() => {
        function getNewResults() {
            refetch();
            debugLog.log('Refetching...');
        }

        const intervalId = !_doneReason && !isFetching && setInterval(() => getNewResults(), refetchInterval);

        return () => {
            if (intervalId) clearInterval(intervalId);
        };
    }, [_doneReason, refetch, refetchInterval, isFetching]);

    return [_doneReason, { isLoading, loadingError }];
};

// eslint-disable-next-line import/prefer-default-export
export { useResultsRefetch };
