import * as React from 'react';
import { Provider } from 'react-redux';
// import { ConnectedRouter } from 'connected-react-router'
import { Store } from 'redux';
import { CssBaseline, ThemeProvider, StylesProvider, createGenerateClassName } from '@material-ui/core';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { QueryClientProvider } from '@tanstack/react-query';
import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { CustomIntlProvider } from './_containers';
import { ApplicationState } from './_store';
import Routes from './_routes';
import { theme } from './_styles';
import { reactQueryClient } from './_query';

dayjs.extend(dayjsUtc);
dayjs.extend(localizedFormat);

// Any additional component props go here.
interface AppProps {
    store: Store<ApplicationState>;
    persistor: Persistor;
}

const generateClassName = createGenerateClassName({
    // dangerouslyUseGlobalCSS: true,
    productionPrefix: 'redbull',
});

// Create an intersection type of the component props and our Redux props.
const App: React.FC<AppProps> = ({ store, persistor }: AppProps) => {
    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <CustomIntlProvider>
                    <QueryClientProvider client={reactQueryClient}>
                        <StylesProvider generateClassName={generateClassName}>
                            <ThemeProvider theme={theme}>
                                <CssBaseline />
                                <Routes />
                            </ThemeProvider>
                        </StylesProvider>
                    </QueryClientProvider>
                </CustomIntlProvider>
            </PersistGate>
        </Provider>
    );
};

// Normally you wouldn't need any generics here (since types infer from the passed functions).
// But since we pass some props from the `index.js` file, we have to include them.
// For an example of a `connect` function without generics, see `./containers/LayoutContainer`.
export default App;
