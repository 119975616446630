import React, { useState } from 'react';
import c from 'classnames';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            maxWidth: '100%',
            maxHeight: '50vh',
            display: 'block',
            borderRadius: theme.shape.borderRadius * 2,
            margin: '0 auto',
        },
        distorted: {
            filter: 'blur(2.5px) brightness(0.7)',
        },
    }),
);

type ImgSrc = React.ImgHTMLAttributes<HTMLImageElement>['src'];

interface Props {
    src?: React.ImgHTMLAttributes<HTMLImageElement>['src'];
    srcList?: ImgSrc[];
    alt: React.ImgHTMLAttributes<HTMLImageElement>['alt'];
    inactive?: boolean;
    display?: boolean;
}

function Snapshot(props: Props) {
    const { display = true, inactive, src, alt, srcList: srcListProp } = props;
    const classes = useStyles();
    const [srcIndex, setSrcIndex] = useState(0);

    const srcList = (srcListProp || [src]).filter(v => !!v);

    const handleImageError = () => {
        const srcLength = srcList.length;
        if (srcLength <= 1) return;
        if (srcIndex + 1 >= srcLength) return;
        setSrcIndex(cur => cur + 1);
    };

    return display ? (
        <img
            className={c(classes.root, {
                [classes.distorted]: inactive,
            })}
            alt={alt}
            src={srcList[srcIndex]}
            onError={handleImageError}
        />
    ) : null;
}

export default Snapshot;
