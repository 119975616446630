import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { createTheme } from '@material-ui/core';
// import red from '@material-ui/core/colors/red';

const breakpoints = createBreakpoints({});

const theme = createTheme({
    palette: {
        primary: { main: '#0596FF' },
        // secondary: { main: '#ef4a8a' },
        // error: { main: red.A700 },
        // type: 'dark',
        text: {
            primary: '#000000',
        },
        background: { default: '#FAFAFA' },
    },
    props: {
        MuiButton: {
            color: 'primary',
            variant: 'contained',
            size: 'large',
        },
    },
    shape: {
        borderRadius: 8,
    },
    typography: {
        fontFamily: ['Inter', 'sans-serif'].join(','),
        fontWeightLight: 300,
        fontWeightRegular: 300,
        fontWeightMedium: 600,
        fontWeightBold: 700,
    },
    overrides: {
        MuiButton: {
            root: { fontWeight: 500 },
            containedPrimary: { boxShadow: '0px 4px 4px rgba(49, 81, 207, 0.25)' },
            sizeLarge: {
                paddingBlock: '0.8125rem',
                paddingInline: '1.5rem',
                [breakpoints.up('sm')]: {
                    minWidth: '22.875rem',
                },
            },
        },
    },
});

// theme.overrides.MuiButton.root = {
//     fontWeight: 500,
//     paddingBlock: theme.spacing(4),
//     paddingInline: theme.spacing(4),
// };

theme.typography.h3 = {
    fontWeight: 600,
    fontSize: '1.5rem',
    [theme.breakpoints.up('sm')]: {
        fontSize: '3rem',
    },
};
theme.typography.body1 = {
    fontWeight: 300,
    fontSize: '1rem',
    [theme.breakpoints.up('sm')]: {
        fontSize: '1.25rem',
    },
};
theme.shadows.push('0px 4px 10px rgba(0, 0, 0, 0.05)'); // accessible as elevation #25

// eslint-disable-next-line import/prefer-default-export
export { theme };
