import React from 'react';
import { AppBar as MuiAppBar, Toolbar, IconButton, Badge } from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircleOutlined';
import { useSelector } from 'react-redux';
import { ApplicationState } from '_store';
import logoSrc from '_assets/images/Atos.svg';
import { Link } from '@reach/router';
import AppBarMenu from './AppBarMenu';
import useStyles from './appBarStyles';

const MENU_ID = 'primary-account-menu';

export default function AppBar(): React.FunctionComponentElement<HTMLDivElement> {
    const classes = useStyles();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    // const { fullName } = useSelector((state: ApplicationState) => state.user);
    const { notifications } = useSelector((state: ApplicationState) => state.layout);
    // useEffectCheckAndRefreshUser();

    const isMenuOpen = Boolean(anchorEl);

    const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>): void => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (): void => {
        setAnchorEl(null);
    };

    return (
        <>
            <MuiAppBar
                elevation={25}
                color="default"
                classes={{ root: classes.appBarRoot, colorDefault: classes.appBarDefault }}
                position="fixed"
            >
                <Toolbar className={classes.toolbar}>
                    <div className={classes.grow}>
                        <Link className={classes.logoWrap} to="/">
                            {/* <AccountCircle style={{ opacity: 0 }} /> */}
                            <img src={logoSrc} alt="Shelf Inspector Logo" className={classes.logo} />
                            {/* <Typography className={classes.title} variant="h6" noWrap>
                                    Fridge Inspector
                                </Typography> */}
                        </Link>
                    </div>
                    <div className={classes.sectionDesktop}>
                        <IconButton
                            edge="end"
                            aria-label="account of current user"
                            aria-controls={MENU_ID}
                            aria-haspopup="true"
                            onClick={handleProfileMenuOpen}
                            color="inherit"
                        >
                            <Badge overlap="rectangular" color="secondary" badgeContent={notifications.length}>
                                <AccountCircleIcon className={classes.accountCircle} />
                            </Badge>
                        </IconButton>
                    </div>
                </Toolbar>
            </MuiAppBar>
            <Toolbar className={classes.toolbar} />
            <AppBarMenu
                menuId={MENU_ID}
                anchorEl={anchorEl}
                onClose={handleMenuClose}
                open={isMenuOpen}
                userName="John Doe"
                notifications={notifications}
            />
        </>
    );
}
