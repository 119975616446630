import React from 'react';
import { Box } from '@material-ui/core';
import { navigate } from '@reach/router';

type Props = {
    data: {
        id: string;
        image_original: string;
    };
};

const Planogram = ({ data }: Props) => {
    const selectPlanogram = () => {
        localStorage.setItem('planogramData', JSON.stringify(data));
        navigate('/app');
    };

    const dateString = data.id;

    const year = dateString.substring(0, 4);
    const month = dateString.substring(5, 7);
    const day = dateString.substring(8, 10);
    const hour = dateString.substring(11, 13);
    const minute = dateString.substring(13, 15);

    const formattedDate = `${day}/${month}/${year}, ${hour}:${minute}`;

    const image = data.image_original.replace(/vertical\.jpg$/, 'horizontal.jpg');

    return (
        <Box onClick={() => selectPlanogram()} style={{ display: 'flex', gap: 12 }}>
            <div style={{ flex: 1 }}>
                <img
                    src={image}
                    style={{ width: '100%', height: '78px', objectFit: 'contain', borderRadius: '16px' }}
                    alt="planogram"
                />
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    flex: 2,
                }}
            >
                <h3 style={{ margin: 1, fontSize: 16, fontWeight: 600 }}>{formattedDate}</h3>
            </div>
        </Box>
    );
};

export default Planogram;
