// import { getCountryCallingCode } from 'react-phone-number-input';
import { CountryCode, getCountries, getCountryCallingCode, CountryCallingCode } from 'libphonenumber-js/mobile';
import en from 'react-phone-number-input/locale/en.json';

const defaultCountry = 'CZ';
const preferredCountriesCodes: CountryCode[] = ['CZ', 'SK'];
const allCountries: CountryCode[] = getCountries();
const allOtherCountries: CountryCode[] = allCountries.filter(cntr => !preferredCountriesCodes.includes(cntr));

// @countryCodes lookslike [['SK', '421'], ...]
const countryCodes: Array<[CountryCode, CountryCallingCode]> = allCountries.map(cntr => [
    cntr,
    getCountryCallingCode(cntr),
]);

const englishNames = new Map(Object.entries(en));
const getEnglishName = (countryCode: string) => englishNames.get(countryCode);

export { defaultCountry, getEnglishName, preferredCountriesCodes, allCountries, allOtherCountries, countryCodes };
