import React, { ReactNode } from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonsWrap: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexWrap: 'wrap-reverse',
            [theme.breakpoints.up('sm')]: {
                justifyContent: 'inherit',
                '& > *': {
                    marginRight: theme.spacing(3),
                    marginBottom: theme.spacing(3),
                },
            },
        },
    }),
);
type Props = {
    children?: ReactNode;
};

function ButtonWrap(props: Props) {
    const { children } = props;
    const classes = useStyles();

    return <Box className={classes.buttonsWrap}>{children}</Box>;
}

export default ButtonWrap;
