import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import c from 'classnames';
import styles from './spinner.module.css';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            color: theme.palette.primary.main,
        },
    }),
);
// interface Props {
//     // not functional atm
//     size?: 'small' | 'normal';
// }

function Spinner() {
    const classes = useStyles();
    // const {} = props;

    return <div className={c(styles.loader, classes.root)}>Loading...</div>;
}

export default Spinner;
