import { createStore, applyMiddleware, Store, combineReducers } from 'redux';
import thunkMiddleware from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { persistStore, persistReducer, Persistor } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { LayoutState, layoutReducer } from './layout';
import { UserState, userReducer } from './user';
import { resultsReducer, ResultsState } from './results';

// The top-level state object
export interface ApplicationState {
    readonly layout: LayoutState;
    readonly user: UserState;
    readonly results: ResultsState;
}

// Create Root Reducer
// eslint-disable-next-line import/prefer-default-export
const createRootReducer = () =>
    combineReducers({
        layout: layoutReducer,
        user: userReducer,
        results: resultsReducer,
    });

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['user', 'layout', 'results'],
};

function setupStore(
    initialState: ApplicationState,
): {
    store: Store;
    persistor: Persistor;
} {
    const middlewares = [thunkMiddleware];

    const composeEnhancers = composeWithDevTools({});

    const persistedReducer = persistReducer(persistConfig, createRootReducer());

    const store = createStore(
        persistedReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(...middlewares),
            // other store enhancers if any
        ),
    );

    const persistor = persistStore(store);

    return { store, persistor };
}

const initialState = window.INITIAL_REDUX_STATE;
const { store, persistor } = setupStore(initialState);

// eslint-disable-next-line import/prefer-default-export
export { store, persistor };
