import { makeStyles, Theme, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        appBarRoot: {
            borderBottomLeftRadius: theme.shape.borderRadius * 2,
            borderBottomRightRadius: theme.shape.borderRadius * 2,
        },
        appBarDefault: { backgroundColor: theme.palette.background.paper },
        toolbar: { minHeight: theme.spacing(10) },
        grow: {
            flexGrow: 1,
            justifyContent: 'flex-start',
            display: 'flex',
        },
        title: {
            display: 'block',
            [theme.breakpoints.up('sm')]: {
                display: 'block',
            },
        },
        sectionDesktop: {
            display: 'flex',
            // [theme.breakpoints.up('md')]: {
            //     display: 'flex',
            // },
        },
        sectionMobile: {
            display: 'flex',
            [theme.breakpoints.up('md')]: {
                display: 'none',
            },
        },
        userListItem: {
            flexDirection: 'column',
            alignItems: 'flex-start',
            [theme.breakpoints.up('md')]: {
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
            },
            // justifyContent: 'space-between',
            // padding: theme.spacing(2),
            // flexDirection: 'column',
        },
        accountCircle: { [theme.breakpoints.up('sm')]: { fontSize: '2.1rem' }, marginRight: 0 },
        userName: { marginRight: theme.spacing(8) },
        logoWrap: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
        },
        logo: {
            // marginRight: theme.spacing(1.2),
            width: theme.spacing(10),
        },
        logoffButton: {
            padding: theme.spacing(1.5, 0),
        },
        langSelectListItem: {
            paddingTop: 0,
        },
    }),
);

export default useStyles;
