import { Dispatch } from 'redux';
import { action, Action } from 'typesafe-actions';

// import { ApplicationState } from '../storeTypes';
import { NOTIFICATIONS } from '_constants';
import { debugLog } from '_utils';
import { LayoutActionTypes, ThemeColors, LayoutState, AvailableLanguages } from './types';

// Here we use the `action` helper function provided by `typesafe-actions`.
// This library provides really useful helpers for writing Redux actions in a type-safe manner.
// For more info: https://github.com/piotrwitek/typesafe-actions
//
// Remember, you can also pass parameters into an action creator. Make sure to
// type them properly as well.

// eslint-disable-next-line import/prefer-default-export
export const setTheme = (theme: ThemeColors): Action => action(LayoutActionTypes.SET_THEME, theme);
export const notificationAdd = (notification: string): Action =>
    action(LayoutActionTypes.NOTIFICATION_ADD, notification);
export const notificationRemove = (notification: string): Action =>
    action(LayoutActionTypes.NOTIFICATION_REMOVE, notification);
export const layoutSetLanguage = (language: AvailableLanguages): Action =>
    action(LayoutActionTypes.SET_LANGUAGE, language);

export function swInitialized() {
    return (dispatch: Dispatch) => {
        dispatch({ type: LayoutActionTypes.SW_INIT });
        debugLog.log('Service worker initialized. App will work offline.');
        // TODO: This should be showed to user so he is informed
        // But this app will not work much offline, so for now let's not bother user about it
        // dispatch({ type: LayoutActionTypes.NOTIFICATION_ADD, payload: NOTIFICATIONS.SW_INITIALIZED });
    };
}

export function swUpdated(registration: RegistrationOptions) {
    return (dispatch: Dispatch) => {
        debugLog.log('Service worker UPDATED. User should restart app.');
        dispatch({ type: LayoutActionTypes.SW_UPDATE, payload: registration });
        dispatch({ type: LayoutActionTypes.NOTIFICATION_ADD, payload: NOTIFICATIONS.SW_UPDATED });
    };
}

// https://dev.to/glukmann/let-users-know-when-you-have-updated-your-service-worker-in-create-react-app-3il9
export function swRefreshApp() {
    return (dispatch: Dispatch, getState: () => { layout: LayoutState }) => {
        debugLog.log('Restarting app for serviceWorker');

        // Remove notification first
        dispatch({ type: LayoutActionTypes.NOTIFICATION_REMOVE, payload: NOTIFICATIONS.SW_UPDATED });

        navigator.serviceWorker.ready.then(registration => {
            const functionalRegistration = getState().layout.serviceWorkerRegistration || registration;

            const registrationWaiting = functionalRegistration?.waiting;

            if (registrationWaiting) {
                registrationWaiting.postMessage({ type: 'SKIP_WAITING' });

                registrationWaiting.addEventListener('statechange', (e: any) => {
                    if (e.target?.state === 'activated') {
                        window.location.reload();
                    }
                });
            }
        });
    };
}
