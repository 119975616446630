import React, { cloneElement, useRef, ReactElement, Children } from 'react';

interface Props {
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onPhotoTaken?: (photo: File) => void;
    children: ReactElement | Array<ReactElement>;
}

function Camera(props: Props) {
    const { onChange, children, onPhotoTaken } = props;
    const inputRef = useRef<HTMLInputElement>(null);

    const onClick = () => {
        if (inputRef.current) inputRef.current.click();
    };

    const handleCameraChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if (files && files[0]) {
            onPhotoTaken?.(files[0]);
        }
        onChange?.(event);
    };

    return (
        <>
            <input
                type="file"
                accept="image/*"
                capture="camera"
                ref={inputRef}
                style={{ display: 'none' }}
                onChange={handleCameraChange}
            />
            {Children.map(children, child => {
                return cloneElement(child, {
                    onClick,
                });
            })}
        </>
    );
}

export default Camera;
