import React from 'react';
import { Typography, TypographyProps } from '@material-ui/core';

const VariantOptions2 = {
    title: 'h3',
    description: 'body1',
    desc: 'body1',
    subtitle: 'h6',
    hint: 'caption',
    subhead: 'subtitle1',
} as const;

interface Props extends TypographyProps {
    myVariant?: 'title' | 'desc' | 'description' | 'subtitle' | 'hint' | 'subhead'; // overrides `variant` props if available
}

function Text(props: Props) {
    const { variant, myVariant, ...rest } = props;
    const parsedVariant = myVariant && myVariant in VariantOptions2 ? VariantOptions2[myVariant] : variant;

    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Typography variant={parsedVariant} {...rest} />;
}

export default Text;
