import { createStyles, makeStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        wrap: {
            flexDirection: 'row',
            width: '100%',
            flexWrap: 'wrap',
            // display: 'flex',
        },
        countrySelect: {
            marginRight: theme.spacing(1.5),
        },
        numberInput: {
            flex: 1,
            width: 'initial',
        },
        listSubheader: {
            backgroundColor: theme.palette.background.default,
        },
        flexBreak: {
            height: 0,
            flexBasis: '100%',
        },
    }),
);

export default useStyles;
