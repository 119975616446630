import React, { useEffect, useState } from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { AxiosError } from 'axios';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { resultsUpdateSession } from '_store/results';
import { LayoutComponents as Layout, Link, Text } from '_components';
import { FormattedMessage } from 'react-intl';
import { ResultOk } from '_query';
import { webPaths } from '_constants';
import { UploadFetchType, uploadPlanogram } from '_query/requests';
import { useMutation } from '@tanstack/react-query';
import PlanogramSelectActions from './PlanogramSelectActions/PlanogramSelectActions';
import Planogram from './Planogram';

type PlanogramType = {
    id: string;
    image_original: string;
};

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            gap: 12,
            [theme.breakpoints.up('sm')]: {
                alignItems: 'flex-start',
            },
        },
    }),
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function PlanogramSelect(props: RouteComponentProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [photoUrl, setPhotoUrl] = useState<string>();
    const [capturedPhoto, setCapturedPhoto] = useState<File>();
    const classes = useStyles();

    const persistedPlanograms = localStorage.getItem('persistedPlanograms')
        ? JSON.parse(localStorage.getItem('persistedPlanograms') || '')
        : [];
    const defaultPlanogram = {
        id: 'FridgePlanogram',
        image_original:
            'https://shelfdemoappstorage.blob.core.windows.net/sessions-photos/lists/planograms_vizualized/FridgePlanogram_vertical.jpg',
    };

    const { isLoading, mutate: uploadMutation, error } = useMutation<ResultOk, AxiosError, UploadFetchType>(
        uploadPlanogram,
        {
            onSuccess: data => {
                const { id } = data;
                dispatch(resultsUpdateSession(id, data));
                navigate(`${webPaths.CONFIRM_PLANOGRAM}`, { state: { sessionId: id } });
            },
        },
    );

    const handleReupload = () => {
        if (capturedPhoto) {
            uploadMutation({ image: capturedPhoto });
        }
    };

    const onPhotoTaken = (newPhoto: File) => {
        const objectUrl = URL.createObjectURL(newPhoto);
        setPhotoUrl(objectUrl);
        setCapturedPhoto(newPhoto);
    };

    const selectPlanogram = () => {
        localStorage.setItem('planogramData', JSON.stringify(defaultPlanogram));
        navigate('/app');
    };

    useEffect(() => {
        if (capturedPhoto) uploadMutation({ image: capturedPhoto });
    }, [capturedPhoto, uploadMutation]);

    return (
        <Layout.Main>
            <Layout.AppBar />

            <Link to={webPaths.TAKE_PHOTOS} style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                <ArrowBackIcon />
                <h3 style={{ fontWeight: 500, textTransform: 'uppercase' }}>
                    <FormattedMessage id="selectPlanogram.buttonTitle" />
                </h3>
            </Link>

            <Box>
                <Text paragraph myVariant="title">
                    <FormattedMessage id="selectPlanogram.title" />
                </Text>
                <PlanogramSelectActions
                    onReupload={handleReupload}
                    loading={isLoading}
                    error={error}
                    onPhotoTaken={onPhotoTaken}
                />
                <Text paragraph myVariant="hint" style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
                    <FormattedMessage id="selectPlanogram.action.option" />
                </Text>
            </Box>

            <Box className={classes.container}>
                {persistedPlanograms.map((planogram: PlanogramType) => (
                    <Planogram key={planogram.id} data={planogram} />
                ))}
                <Box onClick={() => selectPlanogram()} style={{ display: 'flex', gap: 12 }}>
                    <div style={{ flex: 1 }}>
                        <img
                            src={defaultPlanogram.image_original.replace(/vertical\.jpg$/, 'horizontal.jpg')}
                            style={{ width: '100%', minWidth: '80px', height: '78px', objectFit: 'contain', borderRadius: '16px' }}
                            alt="planogram"
                        />
                    </div>
                    <div
                        style={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            flex: 2,
                        }}
                    >
                        <h3 style={{ margin: 1, fontSize: 16, fontWeight: 600 }}>Default</h3>
                    </div>
                </Box>
            </Box>
        </Layout.Main>
    );
}

export default PlanogramSelect;
