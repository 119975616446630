import React from 'react';
import { RouteComponentProps, navigate } from '@reach/router';
import { Box, createStyles, makeStyles, Theme, Tooltip } from '@material-ui/core';
import { ButtonLink, LayoutComponents as Layout, Text } from '_components';
import { webPaths } from '_constants';
import { FormattedMessage } from 'react-intl';
import scanImg from '_assets/images/fluent_scan-text-20-regular.svg';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        buttonNext: {
            marginBottom: '1.5rem',
            paddingBlock: theme.spacing(1.5),
            width: 'fit-content !important',
            [theme.breakpoints.down('xs')]: { width: '100% !important' },
        },
        buttonNextIcon: { marginLeft: 'auto' },
    }),
);

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default function Welcome(props: RouteComponentProps): React.ReactElement {
    const cls = useStyles();

    return (
        <Layout.PageWrap>
            <Layout.Main>
                <Box alignSelf="center" mt={1} mb={1}>
                    <img src={scanImg} alt="Scanner pictogram" />
                </Box>
                <Box mb={6} paddingX={2}>
                    <Text paragraph myVariant="title">
                        <FormattedMessage id="welcome.title" />
                        <Tooltip title="Help">
                            <HelpOutlineIcon
                                onClick={() => navigate(webPaths.HELP)}
                                fontSize="small"
                                xlinkTitle="Help"
                                style={{
                                    verticalAlign: 'middle',
                                    marginLeft: '0.5rem',
                                    color: '#3d7dfb',
                                }}
                            />
                        </Tooltip>
                    </Text>
                    <Text myVariant="description">
                        <FormattedMessage id="welcome.description" />
                    </Text>
                </Box>
                <ButtonLink
                    endIcon={<ArrowRightAltIcon />}
                    size="large"
                    color="primary"
                    variant="contained"
                    to={webPaths.TAKE_PHOTOS}
                    classes={{ root: cls.buttonNext, endIcon: cls.buttonNextIcon }}
                >
                    <FormattedMessage id="welcome.button.continue" />
                </ButtonLink>
            </Layout.Main>
        </Layout.PageWrap>
    );
}
