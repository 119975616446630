import { ResultOk } from '_query';

// eslint-disable-next-line import/prefer-default-export
export enum ResultsActionTypes {
    // CLEAR_SESSION = '@SHOOTING/clear_session',
    UPDATE_SESSION = '@RESULTS/update_session',
}

export interface ResultsState {
    readonly sessions: { [sessionId: string]: ResultOk };
}
