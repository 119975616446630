import { Reducer } from 'redux';
import { UserState, UserActionTypes } from './types';

export const initialState: UserState = {};

const reducer: Reducer<UserState> = (state = initialState, action): UserState => {
    switch (action.type) {
        case UserActionTypes.UPDATE_DATA: {
            return { ...state, ...action.payload };
        }
        case UserActionTypes.REMOVE_DATA: {
            return {};
        }
        // case UserActionTypes.SET_LANGUAGE: {
        //     return { ...state, language: action.language };
        // }
        default: {
            return state;
        }
    }
};

export { reducer as userReducer };
