import React from 'react';
import { notificationRemove } from '_store/layout';
import { makeStyles, createStyles, Button, Card, CardHeader, CardActions, IconButton } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import notificationsObjects from './notificationsObjects';
import NotificationProps from './notificationProps';

const useStyles = makeStyles(theme =>
    createStyles({
        notification: {
            backgroundColor: theme.palette.grey[200],
        },
        headerWrap: {
            display: 'flex',
        },
        closeButtonWrap: {
            padding: theme.spacing(2, 2, 0, 0),
        },
        cardActions: {
            justifyContent: 'flex-end',
            paddingTop: 0,
        },
    }),
);

// const renderAction = (action?: () => void, text?: string) =>
//     action ? <Button onClick={action}>{text || 'OK'}</Button> : undefined;

function Notification(props: NotificationProps) {
    const { id, title, subheader, closable = true, action, actionText } = props;
    const dispatch = useDispatch();
    const classes = useStyles();
    const { formatMessage: f } = useIntl();

    // const onClose = permanent ? undefined : () => dispatch(notificationRemove(id));
    const onClose = () => dispatch(notificationRemove(id));

    return (
        <Card variant="elevation" className={classes.notification}>
            <div className={classes.headerWrap}>
                <CardHeader title={f({ id: title })} subheader={f({ id: subheader })} avatar={<InfoOutlinedIcon />} />
                {closable && (
                    <div className={classes.closeButtonWrap}>
                        <IconButton size="small" onClick={onClose}>
                            <CloseIcon fontSize="small" />
                        </IconButton>
                    </div>
                )}
            </div>
            {!!action && (
                <CardActions className={classes.cardActions}>
                    <Button size="medium" color="primary" onClick={action}>
                        <FormattedMessage id={actionText} defaultMessage="OK" />
                    </Button>
                </CardActions>
            )}
        </Card>
    );
}

function generateNotifications(notificationsList: string[]) {
    const notificationItems = notificationsList.map(notifString => {
        const notifObject = notificationsObjects.find(notifObj => notifObj.id === notifString);

        // eslint-disable-next-line react/jsx-props-no-spreading
        return notifObject && <Notification key={notifObject.id} {...notifObject} />;
    });
    // return notificationItems.length ? notificationItems : <div>Ziadne noive oznamena</div>;
    return notificationItems;
}

export default Notification;

export { generateNotifications };
