import React, { ChangeEvent } from 'react';
import { Menu, ListItem, Typography, MenuProps, Fade, Select } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '_store';
import { layoutSetLanguage, AvailableLanguages } from '_store/layout';
import { UnstyledLink } from '_components/Link';
import { webPaths } from '_constants';

import { generateNotifications } from '../../Notification';
import useStyles from './appBarStyles';

interface Props {
    menuId: MenuProps['id'];
    anchorEl: MenuProps['anchorEl'];
    onClose: MenuProps['onClose'];
    open: MenuProps['open'];
    userName?: string;
    notifications: string[];
}

function AppBarMenu(props: Props) {
    const { menuId, anchorEl, userName, onClose, open, notifications } = props;
    const classes = useStyles();

    const { language } = useSelector((state: ApplicationState) => state.layout);
    const dispatch = useDispatch();

    function handleLanguageChange(e: ChangeEvent<{ value: unknown }>): void {
        const { value } = e.target;

        if (typeof value === 'string') {
            dispatch(layoutSetLanguage(value as AvailableLanguages));
        }
        // setValue(e.target.value);
    }

    return (
        <Menu
            anchorEl={anchorEl}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            id={menuId}
            keepMounted
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={open}
            onClose={onClose}
            TransitionComponent={Fade}
            getContentAnchorEl={null}
            autoFocus={false}
        >
            <ListItem color="primary" className={classes.userListItem}>
                <UnstyledLink to={webPaths.RESULTS}>
                    <Typography className={classes.userName} variant="h6">
                        {userName || 'User Name'}
                    </Typography>
                </UnstyledLink>
                {/* <ButtonLink
                    className={classes.logoffButton}
                    to={webPaths.LOGOFF}
                    color="default"
                    variant="text"
                    size="small"
                >
                    <FormattedMessage id="appbar.button.logoff" defaultMessage="Log off" />
                </ButtonLink> */}
            </ListItem>
            <ListItem divider className={classes.langSelectListItem}>
                <Select native value={language || ''} onChange={handleLanguageChange}>
                    <option value="" disabled>
                        Select language
                    </option>
                    <option value="en">English</option>
                    <option value="fr">français</option>
                    <option value="de">Deutsch</option>
                    <option value="cs">Čeština</option>
                </Select>
            </ListItem>
            <ListItem alignItems="flex-start">
                {notifications.length ? (
                    <Typography variant="subtitle2" color="primary">
                        <FormattedMessage id="appbar.button.newNotifs" defaultMessage="New notifications" />
                    </Typography>
                ) : (
                    <Typography variant="caption" color="textSecondary">
                        <FormattedMessage id="appbar.button.noNewNotifs" defaultMessage="No new notifications" />
                    </Typography>
                )}
            </ListItem>
            {generateNotifications(notifications)}
        </Menu>
    );
}

export default AppBarMenu;
