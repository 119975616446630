import React, { ReactNode } from 'react';
import { IntlProvider } from 'react-intl';
import { getBrowserLanguageCode, translations, getAvailableLanguageCode } from '_i18n';
import { useSelector } from 'react-redux';
import { ApplicationState } from '_store';

interface Props {
    children: ReactNode;
}

// const translations = { en, cz, de };

function CustomIntlProvider(props: Props) {
    const { children } = props;
    const { language } = useSelector((state: ApplicationState) => state.layout);

    const lang = language || getBrowserLanguageCode();
    const availableLang = getAvailableLanguageCode(lang);
    const messages = { ...translations.en, ...translations[availableLang] };

    return (
        <IntlProvider locale={availableLang} messages={messages}>
            {children}
        </IntlProvider>
    );
}

export default CustomIntlProvider;
