import React from 'react';
import { Router as ReachRouter } from '@reach/router';
import { webPaths, webRoutes } from '_constants';
// Routes
import { makeStyles } from '@material-ui/core';
import AppWindow from './AppWindow';
import PlanogramSelect from './PlanogramSelect';
import PlanogramConfirm from './PlanogramConfirm';
import TakePhotos from './TakePhotos';
// import Results from './Results';
import Results from './Results';
import ResultsList from './ResultsList';
import Welcome from './Welcome';

const useStyles = makeStyles({
    routerWrap: { height: 'inherit' },
    pageRouterWrap: {
        '& > div[tabindex]': {
            flexGrow: 1,
            flexDirection: 'column',
            display: 'flex',
        },
    },
});

const Routes: React.FC = () => {
    const { routerWrap, pageRouterWrap } = useStyles();
    return (
        <ReachRouter className={routerWrap}>
            <Welcome default path="/" />
            <AppWindow path="app" className={pageRouterWrap}>
                <TakePhotos default path="/" />
                <ResultsList path={`${webRoutes.RESULTS}`} />
                <Results path={`${webRoutes.RESULTS}/:sessionId`} />
            </AppWindow>
            <PlanogramSelect path={`${webPaths.SELECT_PLANOGRAM}`} />
            <PlanogramConfirm path={`${webPaths.CONFIRM_PLANOGRAM}`} />
        </ReachRouter>
    );
};

export default Routes;
