import React from 'react';
import { RouteComponentProps, useLocation } from '@reach/router';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { ButtonLink, ButtonWrap, LayoutComponents as Layout, LoadingBar, Snapshot, Text } from '_components';
import { FormattedMessage, useIntl } from 'react-intl';
import { ApplicationState } from '_store';
import { CameraAltOutlined, ArrowBackOutlined } from '@material-ui/icons';
import { webPaths } from '_constants';
import ResultGrid from './components/ResultGrid';
import { useResultsRefetch } from './hooks';

type Props = RouteComponentProps & {
    sessionId?: string;
};

type LocationState = {
    photoUrl?: string;
};

const PROCESSING_TIMEOUT_SECS = 120;

function Results(props: Props) {
    const { sessionId } = props;
    const { formatMessage: f } = useIntl();
    const location = useLocation();
    const theme = useTheme();
    const mediaSmallUp = useMediaQuery(theme.breakpoints.up('sm'));

    const results = useSelector((state: ApplicationState) => state.results);
    const session = sessionId ? results.sessions[sessionId] : undefined;

    const [doneReason, { isLoading }] = useResultsRefetch(sessionId, {
        processingTimeoutSecs: PROCESSING_TIMEOUT_SECS,
    });

    const capturedPhotoUrl = ((location.state as LocationState) || {}).photoUrl;

    const statusOk = !doneReason || doneReason === 'completed';

    const imageUrls = session?.photos_to_display?.map(urlPath => `${session?.blob_base}/${urlPath}`);

    return (
        <Layout.Main>
            <Box mb={6}>
                <Text paragraph myVariant="title">
                    <FormattedMessage id="results.title" />
                </Text>
                <Text color={statusOk ? 'textPrimary' : 'error'} paragraph myVariant="desc">
                    <FormattedMessage id={`results.status.${doneReason}`} />
                </Text>
                {!statusOk && <code>sessionId: {sessionId}</code>}
            </Box>

            {doneReason === 'completed' && imageUrls && imageUrls.length > 1 ? (
                <ResultGrid analyseResult={session?.results} imageUrls={imageUrls} />
            ) : (
                <Snapshot
                    inactive={!doneReason}
                    srcList={[capturedPhotoUrl, `${session?.blob_base}/${session?.image_original}`]}
                    alt="Original"
                />
            )}

            {!mediaSmallUp && (
                <Box>
                    <Box display={!doneReason ? 'none' : undefined} mt={6}>
                        <ButtonWrap>
                            <ButtonLink variant="text" startIcon={<ArrowBackOutlined />} to={webPaths.TAKE_PHOTOS}>
                                <FormattedMessage id="results.button.backToHome" />
                            </ButtonLink>
                            <ButtonLink startIcon={<CameraAltOutlined />} to={webPaths.TAKE_PHOTOS}>
                                <FormattedMessage id="results.button.camera" />
                            </ButtonLink>
                        </ButtonWrap>
                    </Box>
                </Box>
            )}

            <LoadingBar
                loading={!doneReason}
                text={f({ id: `analyse.status.${isLoading ? 'isLoading' : 'inProgress'}` })}
            />
        </Layout.Main>
    );
}

export default Results;
