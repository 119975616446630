import { action, Action } from 'typesafe-actions';
import { ThunkAction } from 'redux-thunk';
import { ResultOk } from '_query';
import { ResultsActionTypes } from './types';

// const RESULT_RETRY_DELAY = 3000;
// const RESULT_NOT_YET_CODES = [202];

export const resultsUpdateSession = (sessionId: string, resultData: ResultOk) =>
    action(ResultsActionTypes.UPDATE_SESSION, { sessionId, resultData });

export type ActionType = ThunkAction<Promise<void>, {}, {}, Action>;
