import React from 'react';
import { Box, Grid, Slider, useMediaQuery, useTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { ButtonLink, ButtonWrap, Text } from '_components';
import { AnalyseResult } from '_query';
import { ArrowBackOutlined, CameraAltOutlined } from '@material-ui/icons';
import { webPaths } from '_constants';

type Props = { imageUrls?: string[]; analyseResult?: AnalyseResult };

function DesktopResults(props: Props) {
    const { imageUrls, analyseResult: ar } = props;
    const [value, setValue] = React.useState<number>(0);
    const theme = useTheme();
    const mediaSmallUp = useMediaQuery(theme.breakpoints.up('md'));

    const handleChange = (event: any, newValue: number | number[]) => {
        event.preventDefault();
        setValue(newValue as number);
    };

    const percentage = props.analyseResult && Math.round(props.analyseResult.planogram_compliance * 100);

    return (
        <Grid container spacing={6} xs={12}>
            <Grid item xs={6} spacing={3}>
                <div style={{ width: '100%', position: 'relative', overflow: 'hidden' }}>
                    {imageUrls && (
                        <img style={{ width: '100%', borderRadius: 16 }} src={imageUrls[0]} alt="Captured by Camera" />
                    )}
                    {imageUrls && (
                        <img
                            style={{
                                width: '100%',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                opacity: value,
                                borderRadius: 16,
                            }}
                            src={imageUrls[1].replace('png', 'jpg')}
                            alt="planogram"
                        />
                    )}
                </div>
                <Slider
                    step={0.01}
                    min={0}
                    max={1}
                    value={value}
                    onChange={handleChange}
                    aria-labelledby="continuous-slider"
                />
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <Text myVariant="hint">
                        <FormattedMessage id="results.analysis.analyzedPhoto" />
                    </Text>
                    <Text myVariant="hint">
                        <FormattedMessage id="results.analysis.planogram" />
                    </Text>
                </div>
            </Grid>
            <Grid item xs={6}>
                <Grid container spacing={3} style={{ marginTop: 12 }}>
                    <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                        {mediaSmallUp ? (
                            <Text myVariant="title" style={{ flex: 1 }}>
                                <FormattedMessage id="results.grid.planogramMatch" />:
                            </Text>
                        ) : (
                            <h3 style={{ flex: 1, fontSize: '2rem' }}>
                                <FormattedMessage id="results.grid.planogramMatch" />:
                            </h3>
                        )}
                        <Text myVariant="title" color="primary">
                            {percentage}%
                        </Text>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 12, marginTop: 4 }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <h4 style={{ margin: 0 }}>
                                <FormattedMessage id="results.analysis.add" />:
                            </h4>
                            <Text style={{ textTransform: 'capitalize' }} myVariant="desc">
                                {props.analyseResult?.skus_to_add?.map((prop: string, index: number) => {
                                    const containsBold = prop.includes('<b>');
                                    const htmlProp = containsBold ? `${prop}` : prop; // possibility that there will be some text before/after the bold prop
                                    return (
                                        <div key={index}>
                                            <span dangerouslySetInnerHTML={{ __html: htmlProp }} />
                                        </div>
                                    );
                                })}
                            </Text>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <h4 style={{ margin: 0 }}>
                                <FormattedMessage id="results.analysis.remove" />:
                            </h4>
                            <Text style={{ textTransform: 'capitalize' }} myVariant="desc">
                                {props.analyseResult?.skus_to_remove?.map((prop: string, index: number) => {
                                    const containsBold = prop.includes('<b>');
                                    const htmlProp = containsBold ? `${prop}` : prop; // possibility that there will be some text before/after the bold prop
                                    return (
                                        <div key={index}>
                                            <span dangerouslySetInnerHTML={{ __html: htmlProp }} />
                                        </div>
                                    );
                                })}
                            </Text>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <h4 style={{ margin: 0 }}>
                                <FormattedMessage id="results.analysis.misplaced" />:
                            </h4>
                            <Text style={{ textTransform: 'capitalize' }} myVariant="desc">
                                {props.analyseResult?.skus_to_rearrange?.map((prop: string, index: number) => {
                                    const containsBold = prop.includes('<b>');
                                    const htmlProp = containsBold ? `${prop}` : prop; // possibility that there will be some text before/after the bold prop
                                    return (
                                        <div key={index}>
                                            <span dangerouslySetInnerHTML={{ __html: htmlProp }} />
                                        </div>
                                    );
                                })}
                            </Text>
                        </div>
                    </div>
                    <Box>
                        <Box mt={6}>
                            <ButtonWrap>
                                <ButtonLink variant="text" startIcon={<ArrowBackOutlined />} to={webPaths.TAKE_PHOTOS}>
                                    <FormattedMessage id="results.button.backToHome" />
                                </ButtonLink>
                                <ButtonLink startIcon={<CameraAltOutlined />} to={webPaths.TAKE_PHOTOS}>
                                    <FormattedMessage id="results.button.camera" />
                                </ButtonLink>
                            </ButtonWrap>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default DesktopResults;
