import React, { ReactNode } from 'react';
import c from 'classnames';
import { useDebounce } from 'use-debounce';

import { makeStyles, createStyles, Paper, Box } from '@material-ui/core';
import Text from '../Text';

const useStyles = makeStyles(({ palette, spacing, shape }) =>
    createStyles({
        root: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        paper: {
            backgroundColor: palette.primary.main,
            paddingBlock: spacing(4.5),
            paddingInline: spacing(6),
            borderRadius: shape.borderRadius * 2,
            width: '520px',
            maxWidth: '90vw',
            display: 'flex',
            justifyContent: 'center',
        },
        text: {
            color: '#ffffff',
            fontWeight: 500,
        },
        animatedDots: {
            '&::after': {
                display: 'inline-block',
                animation: '$dotty steps(1,end) 1.5s infinite',
                content: "'...'",
                position: 'fixed',
            },
        },
        '@keyframes dotty': {
            '0%': { content: "''" },
            '25%': { content: "'.'" },
            '50%': { content: "'..'" },
            '75%': { content: "'...'" },
            '100%': { content: "''" },
        },
    }),
);

const DELAY_BEFORE_CLOSE = 600; // time before LoadingBar is closed, for better UXS

interface Props {
    children?: ReactNode;
    loading?: boolean;
    text?: string;
    animatedDots?: boolean;
}

function LoadingBar(props: Props) {
    const { loading, children, text, animatedDots = true } = props;
    const classes = useStyles();
    const [delayedLoading] = useDebounce(loading, loading ? 0 : DELAY_BEFORE_CLOSE);

    return delayedLoading ? (
        <Box className={classes.root}>
            <Paper color="primary" className={classes.paper}>
                {text && (
                    <Text className={c(classes.text, animatedDots && classes.animatedDots)} myVariant="desc">
                        {text}
                    </Text>
                )}
                {children}
            </Paper>
        </Box>
    ) : null;
}

export default LoadingBar;
