import React, { useEffect, useState } from 'react';
import { Box, makeStyles, Modal, Tooltip } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useDispatch } from 'react-redux';
import { InfoOutlined } from '@material-ui/icons';
import { AxiosError } from 'axios';
import { RouteComponentProps, useNavigate } from '@reach/router';
import { resultsUpdateSession } from '_store/results';
import { LayoutComponents as Layout, LoadingBar, Snapshot, Text, Camera, Link } from '_components';
import { FormattedMessage, useIntl } from 'react-intl';
import { ResultOk } from '_query';
import { webPaths } from '_constants';
import { UploadFetchType, uploadPhotoFetch, uploadPlanogram } from '_query/requests';
import { useMutation } from '@tanstack/react-query';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import TakePhotosActions from './components/TakePhotosActions';

function getModalStyle() {
    return {
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
        position: 'absolute',
        minWidth: 400,
        backgroundColor: theme.palette.background.paper,
        borderRadius: '8px',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
    },
}));

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function TakePhotos(props: RouteComponentProps) {
    const { formatMessage: f } = useIntl();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [photoUrl, setPhotoUrl] = useState<string>();
    const [planogramUrl, setPlanogramUrl] = useState<string>();
    const [capturedPhoto, setCapturedPhoto] = useState<File>();
    const [capturedPlanogram, setCapturedPlanogram] = useState<File>();
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [open, setOpen] = useState(false);
    const [isPlanogramSelected, setIsPlanogramSelected] = useState(false);
    const BLOB_BASE = 'https://shelfdemoappstorage.blob.core.windows.net/sessions-photos';

    const planogramData =
        localStorage.getItem('planogramData') && JSON.parse(localStorage.getItem('planogramData') || '');

    const { isLoading, mutate: uploadMutation, error } = useMutation<ResultOk, AxiosError, UploadFetchType>(
        uploadPhotoFetch,
        {
            onSuccess: data => {
                const { id } = data;
                dispatch(resultsUpdateSession(id, data));
                navigate(`${webPaths.RESULTS}/${id}`, { state: { photoUrl } });
            },
        },
    );

    const { isLoading: planogramLoading, mutate: upladPlanogramMutation, error: planogramError } = useMutation<
        ResultOk,
        AxiosError,
        UploadFetchType
    >(uploadPlanogram, {
        onSuccess: data => {
            const { id } = data;
            dispatch(resultsUpdateSession(id, data));
            navigate(`${webPaths.CONFIRM_PLANOGRAM}`, { state: { sessionId: id } });
        },
    });

    const handleModal = () => {
        setOpen(!open);
    };

    const handleReupload = () => {
        if (capturedPhoto) {
            uploadMutation({ image: capturedPhoto });
        }
    };

    const onPhotoTaken = (newPhoto: File) => {
        const objectUrl = URL.createObjectURL(newPhoto);
        setPhotoUrl(objectUrl);
        setCapturedPhoto(newPhoto);
    };

    const onPlanogramTaken = (newPhoto: File) => {
        setOpen(false);
        const objectUrl = URL.createObjectURL(newPhoto);
        setPlanogramUrl(objectUrl);
        setCapturedPlanogram(newPhoto);
    };

    useEffect(() => {
        if (capturedPhoto) {
            uploadMutation({ image: capturedPhoto, planogramId: planogramData?.id });
            setCapturedPhoto(undefined);
        }
        if (capturedPlanogram) {
            upladPlanogramMutation({ image: capturedPlanogram });
            setCapturedPlanogram(undefined);
        }
        if (planogramData) {
            setPlanogramUrl(`${planogramData.image_original}`);
            setIsPlanogramSelected(true);
        } else {
            localStorage.setItem(
                'planogramData',
                JSON.stringify({
                    id: 'FridgePlanogram',
                    image_original: `${BLOB_BASE}/lists/planograms_vizualized/FridgePlanogram_vertical.jpg`,
                }),
            );
            setPlanogramUrl(`${BLOB_BASE}/lists/planograms_vizualized/FridgePlanogram_vertical.jpg`);
            setIsPlanogramSelected(true);
        }
    }, [capturedPhoto, capturedPlanogram, uploadMutation, upladPlanogramMutation, planogramData]);

    const body = (
        <div style={modalStyle} className={classes.paper}>
            <Text paragraph myVariant="title">
                <FormattedMessage id="modal.title" />
            </Text>
            <Text>
                <FormattedMessage id="modal.description" />
            </Text>
            <div style={{ display: 'flex', justifyContent: 'center', gap: 24 }}>
                <Camera onPhotoTaken={onPlanogramTaken}>
                    <h3 style={{ fontWeight: 500, color: '#0080F0', fontSize: '13px', cursor: 'pointer' }}>
                        <FormattedMessage id="modal.action.takePhoto" />
                    </h3>
                </Camera>
                <Link to={webPaths.SELECT_PLANOGRAM} style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                    <h3 style={{ fontWeight: 500, fontSize: '13px' }}>
                        <FormattedMessage id="modal.action.choose" />
                    </h3>
                </Link>
            </div>
        </div>
    );

    return (
        <Layout.Main>
            <Link to={webPaths.WELCOME} style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                <ArrowBackIcon />
                <h3 style={{ fontWeight: 500, textTransform: 'uppercase' }}>
                    <FormattedMessage id="takePhoto.buttonTitle" />
                </h3>
            </Link>

            <Box mb={6}>
                <Text paragraph myVariant="title">
                    <FormattedMessage id="takePhoto.title" />
                    <Tooltip title="Help">
                        <HelpOutlineIcon
                            onClick={() => navigate(webPaths.HELP)}
                            fontSize="small"
                            xlinkTitle="Help"
                            style={{
                                verticalAlign: 'middle',
                                marginLeft: '0.5rem',
                                color: '#3d7dfb',
                            }}
                        />
                    </Tooltip>
                </Text>
                <Text myVariant="desc">
                    <FormattedMessage id="takePhoto.description" />
                </Text>
            </Box>

            <Snapshot alt="Captured by Camera" src={photoUrl} inactive={isLoading} display={!!photoUrl} />
            {!isPlanogramSelected && (
                <Snapshot
                    alt="Reference photo"
                    src={planogramUrl}
                    inactive={planogramLoading}
                    display={!!planogramUrl}
                />
            )}

            <Box mt={4} mb={4}>
                <TakePhotosActions
                    onReupload={handleReupload}
                    loading={isLoading}
                    error={error}
                    onPhotoTaken={onPhotoTaken}
                />
            </Box>

            {!isLoading && (
                <Box>
                    <Text myVariant="subtitle">
                        <FormattedMessage id="takePhoto.selectedPlanogram" />
                    </Text>
                    <Text myVariant="hint" style={{ display: 'flex', alignItems: 'center', gap: 2, marginBottom: 8 }}>
                        <InfoOutlined fontSize="inherit" />
                        <FormattedMessage id="takePhoto.clickHint" />
                    </Text>
                    <div onClick={() => handleModal()}>
                        {planogramUrl ? (
                            <img
                                src={planogramUrl.replace(/vertical\.jpg$/, 'horizontal.jpg')}
                                style={{ width: '100%', height: '237px', objectFit: 'contain', borderRadius: '16px' }}
                                alt="selectedPlanogram"
                            />
                        ) : (
                            <Text myVariant="desc">
                                <FormattedMessage id="takePhoto.noPlanogram" />
                            </Text>
                        )}
                    </div>
                </Box>
            )}

            <LoadingBar loading={isLoading} text={f({ id: 'analyse.status.uploading' })} />
            <LoadingBar loading={planogramLoading} text={f({ id: 'analyse.status.uploading' })} />

            <Modal
                open={open}
                onClose={() => setOpen(false)}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {body}
            </Modal>
        </Layout.Main>
    );
}

export default TakePhotos;
