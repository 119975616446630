import Axios, { AxiosError, AxiosResponse } from 'axios';
import { asyncTimeout } from '../other';

const authorizedAxios = Axios.create();

// async function refreshAccessFetch() {
//     // Also check for new version of service worker
//     // Not related to authorization but this seems like suitable place for this check
//     serviceWorkerUpdate();

//     // refreshAccessFetch
//     const { data } = await Axios.post(`${apiUrl}/${apiPaths.REFRESH_TOKEN}`);
//     const { expiration } = data;

//     setLoginExpiration(expiration);
//     return expiration;
// }

// Checks if access token is expired and request new one if needed
// authorizedAxios.interceptors.request.use(
//     async config => {
//         if (isLoginExpired()) {
//             try {
//                 await refreshAccessFetch();
//             } catch (error) {
//                 debugLog.warn('Some error occured during refresh of RefreshToken');
//                 // debugLog.warn(error);
//                 debugLog.warn(error.response);
//                 const { data, status } = error.response;
//                 if (status === 401 || data?.msg) navigate(webPaths.LOGOFF);
//                 // TODO should abort request
//             }
//         }

//         return config;
//     },
//     error => {
//         // Do something with request error
//         return Promise.reject(error);
//     },
// );

const extractErrorResponseErrors = (error: AxiosError) => error?.response?.data?.errors || {};

async function retryFetch(
    fetch: () => Promise<AxiosResponse<any>>,
    okStatuses: Array<number>,
    waitTime: number,
    retries: number,
): Promise<AxiosResponse<any>> {
    // try {
    const response = await fetch();
    const { status } = response;

    if (okStatuses.includes(status) && retries > 0) {
        await asyncTimeout(waitTime);
        return retryFetch(fetch, okStatuses, waitTime, retries - 1);
    }
    return response;
    // } catch (error) {
    //     return error;
    // }
}

export default authorizedAxios;
export { extractErrorResponseErrors, retryFetch };
