import React from 'react';
import { Typography, Link, Box, makeStyles, createStyles } from '@material-ui/core';
import datasenticsLogo from '_assets/images/datasentics-logo-blue.png';
// import Link from '../Link'
const useStyles = makeStyles(() =>
    createStyles({
        logo: {
            margin: '0 5px 5px 2px',
            height: 18,
        },
        typography: {
            display: 'flex',
            justifyContent: 'center',
        },
    }),
);

interface Props {
    smallMargin?: boolean;
}

function Footer(props: Props): React.ReactElement {
    const { smallMargin } = props;
    const classes = useStyles();

    const marginBottom = smallMargin ? 0 : 2;

    return (
        <Box component="footer" mt={2} mb={marginBottom}>
            <Typography className={classes.typography} variant="body2" color="textSecondary" align="center">
                Copyright ©
                <Link target="_blank" color="inherit" href="https://www.datasentics.com/">
                    <img className={classes.logo} src={datasenticsLogo} alt="Datasentics logo" />
                </Link>{' '}
                {new Date().getFullYear()}
            </Typography>
        </Box>
    );
}

export default Footer;
