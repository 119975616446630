import React from 'react';
import { MenuItem } from '@material-ui/core';
import { CountryCode, CountryCallingCode, getCountryCallingCode } from 'libphonenumber-js/mobile';
import { countryCodes, getEnglishName } from '_constants/phone';
import { isCountryCodeType } from '_types';
import CountryFlag from '../CountryFlag';

// Returns most probable CountryCode based on callingCopde and preferredCountry
const getProbableCountry = (callingCode: CountryCallingCode, preferredCountry: CountryCode) => {
    const possibleCountries = countryCodes.filter(cntr => callingCode === cntr[1]).map(cntr => cntr[0]);

    if (possibleCountries.includes(preferredCountry)) return preferredCountry;
    if (callingCode.toString() === '1') return 'US'; // edge case
    return possibleCountries[0];
};

const generateSelectOptions = (options: Array<CountryCode>) => {
    return options.map(country => {
        return (
            <MenuItem key={country} value={country}>
                <CountryFlag country={country} size="small" />
                {getEnglishName(country)} +{getCountryCallingCode(country)}
            </MenuItem>
        );
    });
};

const renderCountrySelectValue = (value: unknown) =>
    isCountryCodeType(value) ? (
        <>
            <CountryFlag country={value} size="small" /> +{getCountryCallingCode(value)}
        </>
    ) : (
        <>{value}</>
    );

export { getProbableCountry, generateSelectOptions, renderCountrySelectValue };
