import React, { useEffect } from 'react';
import { Box } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { RouteComponentProps, useLocation } from '@reach/router';
import { ButtonLink, ButtonWrap, LayoutComponents as Layout, Link, LoadingBar, Text } from '_components';
import { FormattedMessage } from 'react-intl';
import { webPaths } from '_constants';
import { ArrowBackOutlined, Check } from '@material-ui/icons';
import { useResultsRefetch } from '_routes/Results/hooks';
import { useSelector } from 'react-redux';
import { ApplicationState } from '_store';

type LocationState = {
    sessionId?: string;
};

const persistedPlanograms = localStorage.getItem('persistedPlanograms')
    ? JSON.parse(localStorage.getItem('persistedPlanograms') || '')
    : [];

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function PlanogramConfirm(props: RouteComponentProps) {
    const location = useLocation();

    const { sessionId } = (location.state as LocationState) || {};

    const [doneReason, { isLoading }] = useResultsRefetch(sessionId, {
        processingTimeoutSecs: 120,
    });

    const statusOk = !doneReason || doneReason === 'completed';
    const results = useSelector((state: ApplicationState) => state.results);
    const session = sessionId ? results.sessions[sessionId] : undefined;
    const imageUrls = session?.photos_to_display?.map(urlPath => `${session?.blob_base}/${urlPath}`);

    useEffect(() => {
        if (doneReason === 'completed' && imageUrls) {
            localStorage.setItem('planogramData', JSON.stringify({ id: sessionId, image_original: imageUrls[0] }));
            const originalPersistentPlanograms = persistedPlanograms;
            const newPersintentPlanograms = [];
            if (originalPersistentPlanograms.length > 0) {
                newPersintentPlanograms.push(originalPersistentPlanograms[0]);
            }
            if (originalPersistentPlanograms.length > 1) {
                newPersintentPlanograms.push(originalPersistentPlanograms[1]);
            }
            newPersintentPlanograms.push({ id: sessionId, image_original: imageUrls[0] });
            localStorage.setItem('persistedPlanograms', JSON.stringify(newPersintentPlanograms));
        }
    }, [doneReason, imageUrls, sessionId]);

    return (
        <Layout.Main>
            <Layout.AppBar />

            <Link to={webPaths.TAKE_PHOTOS} style={{ display: 'flex', alignItems: 'center', gap: 4 }}>
                <ArrowBackIcon />
                <h3 style={{ fontWeight: 500, textTransform: 'uppercase' }}>
                    <FormattedMessage id="confirmPlanogram.buttonTitle" />
                </h3>
            </Link>
            <Box>
                <Text paragraph myVariant="title">
                    <FormattedMessage id="confirmPlanogram.title" />
                </Text>
                <Text color={statusOk ? 'textPrimary' : 'error'} paragraph myVariant="desc">
                    <FormattedMessage id={`results.status.${doneReason}`} />
                </Text>
                {!statusOk && <code>sessionId: {sessionId}</code>}
            </Box>

            {/* {doneReason === 'completed' && imageUrls ? (
                <Box>
                    <img style={{ width: '100%', borderRadius: 16 }} src={imageUrls[0]} alt="planogram" />
                </Box>
            ) : (
                <Snapshot
                    inactive={!doneReason}
                    srcList={[`${session?.blob_base}/${session?.image_original}`]}
                    alt="Original"
                />
            )} */}

            {doneReason === 'completed' && imageUrls && (
                <Box>
                    <img style={{ width: '100%', borderRadius: 16 }} src={imageUrls[0]} alt="planogram" />
                </Box>
            )}

            <Box>
                <Box mt={6}>
                    <ButtonWrap>
                        {doneReason === 'completed' && imageUrls && (
                            <>
                                <ButtonLink
                                    onClick={() => localStorage.removeItem('planogramData')}
                                    variant="text"
                                    startIcon={<ArrowBackOutlined />}
                                    to={webPaths.TAKE_PHOTOS}
                                >
                                    <FormattedMessage id="confirmPlanogram.action.button.retake" />
                                </ButtonLink>
                                <ButtonLink startIcon={<Check />} to={webPaths.TAKE_PHOTOS}>
                                    <FormattedMessage id="confirmPlanogram.action.button.confirm" />
                                </ButtonLink>
                            </>
                        )}
                        {doneReason === 'failed' || doneReason === 'noDetection' && (
                            <>
                                <ButtonLink
                                    onClick={() => localStorage.removeItem('planogramData')}
                                    variant="text"
                                    startIcon={<ArrowBackOutlined />}
                                    to={webPaths.TAKE_PHOTOS}
                                >
                                    <FormattedMessage id="confirmPlanogram.action.button.retake" />
                                </ButtonLink>
                            </>
                        )}
                    </ButtonWrap>
                </Box>
            </Box>
            <LoadingBar loading={!doneReason} text={isLoading ? 'Processing...' : 'Waiting for results...'} />
        </Layout.Main>
    );
}

export default PlanogramConfirm;
