import React, { ReactNode } from 'react';
import { makeStyles } from '@material-ui/core';
import c from 'classnames';

type PageProps = { className?: string; children?: ReactNode };

const useStyles = makeStyles(() => ({
    pageWrap: {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
    },
}));

function PageWrap(props: PageProps): React.ReactElement {
    const classes = useStyles();
    const { children, className } = props;

    return <div className={c(classes.pageWrap, className)}>{children}</div>;
}

export default PageWrap;
