import * as Yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js/mobile';

const validations = {
    phone: Yup.string()
        // .min(10, 'Musí obsahovat alespoň 9 číslic včetně kódu země')
        // .max(20, 'Může obsahovat maximálně 16 číslic včetně kódu země')
        .required('Povinné')
        .test('isPhoneNumber', 'Není platné číslo mobilního telefonu', (value: string) => {
            if (!value) return false;
            const parsedNumber = parsePhoneNumberFromString(value);
            return !!parsedNumber && parsedNumber.isValid();
        }),
    fullName: Yup.string()
        .min(4, 'Musí obsahovat 4 nebo více znaků')
        .max(64, 'Může obsahovat maximálně 64 znaků')
        .matches(/\S+\s\S+/, 'Uveďte jméno a také příjmení')
        .required('Povinné'),
    password: Yup.string().required('Povinné'),
};

export default validations;
