import React from 'react';
import { Divider, Grid, Slider, useMediaQuery, useTheme } from '@material-ui/core';
import { FormattedMessage } from 'react-intl';
import { Text } from '_components';
import { AnalyseResult } from '_query';
import DesktopResults from './DesktopResults';

type Props = { imageUrls?: string[]; analyseResult?: AnalyseResult };

function ResultGrid(props: Props) {
    const { imageUrls } = props;
    const theme = useTheme();
    const mediaSmallUp = useMediaQuery(theme.breakpoints.up('sm'));
    const [value, setValue] = React.useState<number>(0);

    const handleChange = (event: any, newValue: number | number[]) => {
        event.preventDefault();
        setValue(newValue as number);
    };

    const percentage = props.analyseResult && Math.round(props.analyseResult.planogram_compliance * 100);

    if (mediaSmallUp) return <DesktopResults imageUrls={imageUrls} analyseResult={props.analyseResult} />;

    return (
        <Grid container spacing={3}>
            <div style={{ width: '100%', position: 'relative', overflow: 'hidden' }}>
                {imageUrls && (
                    <img style={{ width: '100%', borderRadius: 16 }} src={imageUrls[0]} alt="Captured by Camera" />
                )}
                {imageUrls && (
                    <img
                        style={{
                            width: '100%',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            opacity: value,
                            borderRadius: 16,
                        }}
                        src={imageUrls[1].replace('png', 'jpg')}
                        alt="planogram"
                    />
                )}
            </div>
            <Slider
                step={0.01}
                min={0}
                max={1}
                value={value}
                onChange={handleChange}
                aria-labelledby="continuous-slider"
            />
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                <Text myVariant="hint">
                    <FormattedMessage id="results.analysis.analyzedPhoto" />
                </Text>
                <Text myVariant="hint">
                    <FormattedMessage id="results.analysis.planogram" />
                </Text>
            </div>
            <Divider variant="middle" />
            <Grid item xs={12} md={3}>
                <Grid container spacing={3} style={{ marginTop: 12 }}>
                    <div style={{ width: '100%', display: 'flex', gap: 6 }}>
                        <Text myVariant="title">
                            <FormattedMessage id="results.grid.planogramMatch" />:
                        </Text>
                        <Text myVariant="title" color="primary">
                            {percentage}%
                        </Text>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 12, marginTop: 4 }}>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <h4 style={{ margin: 0 }}>
                                <FormattedMessage id="results.analysis.add" />:
                            </h4>
                            <Text myVariant="desc">
                                {props.analyseResult?.skus_to_add?.map((prop: string, index: number) => {
                                    const containsBold = prop.includes('<b>');
                                    const htmlProp = containsBold ? `${prop}` : prop; // possibility that there will be some text before/after the bold prop
                                    return (
                                        <div key={index}>
                                            <span dangerouslySetInnerHTML={{ __html: htmlProp }} />
                                        </div>
                                    );
                                })}
                            </Text>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <h4 style={{ margin: 0 }}>
                                <FormattedMessage id="results.analysis.remove" />:
                            </h4>
                            <Text style={{ textTransform: 'capitalize' }} myVariant="desc">
                                {props.analyseResult?.skus_to_remove?.map((prop: string, index: number) => {
                                    const containsBold = prop.includes('<b>');
                                    const htmlProp = containsBold ? `${prop}` : prop; // possibility that there will be some text before/after the bold prop
                                    return (
                                        <div key={index}>
                                            <span dangerouslySetInnerHTML={{ __html: htmlProp }} />
                                        </div>
                                    );
                                })}
                            </Text>
                        </div>
                        <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <h4 style={{ margin: 0 }}>
                                <FormattedMessage id="results.analysis.misplaced" />:
                            </h4>
                            <Text style={{ textTransform: 'capitalize' }} myVariant="desc">
                                {props.analyseResult?.skus_to_rearrange?.map((prop: string, index: number) => {
                                    const containsBold = prop.includes('<b>');
                                    const htmlProp = containsBold ? `${prop}` : prop; // possibility that there will be some text before/after the bold prop
                                    return (
                                        <div key={index}>
                                            <span dangerouslySetInnerHTML={{ __html: htmlProp }} />
                                        </div>
                                    );
                                })}
                            </Text>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default ResultGrid;
