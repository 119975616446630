import React, { forwardRef, Ref } from 'react';
import { Link as ReachLink, LinkProps } from '@reach/router';
import { Link as MuiLink, makeStyles, Button, ButtonProps } from '@material-ui/core';
// import { LinkProps } from '@material-ui/core/Link';
import c from 'classnames';

const useStyles = makeStyles({
    unstyledLink: {
        textDecoration: 'inherit',
        color: 'inherit',
    },
});

// Combination of MUI Link & ReachRouter Link
function Link(props: any) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <MuiLink component={ReachLink} {...props} />;
}

// Combination of MUI Link & ReachRouter Link
function ButtonLink(props: ButtonProps | LinkProps<string>) {
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <Button component={ReachLink} style={{ width: '100%' }} {...(props as any)} />;
}

const UnstyledLink = forwardRef((props: any, ref: Ref<any>) => {
    const classes = useStyles();
    const { className, ...rest } = props;
    // eslint-disable-next-line react/jsx-props-no-spreading
    return <ReachLink className={c(classes.unstyledLink, className)} {...rest} ref={ref} />;
});

export default Link;
export { UnstyledLink, ButtonLink };
