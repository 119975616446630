import React, { ReactNode } from 'react';
import { makeStyles, createStyles, Theme, Container } from '@material-ui/core';
import c from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        main: {
            display: 'flex',
            flexGrow: 1,
            flexDirection: 'column',
            justifyContent: 'space-between',
            paddingTop: theme.spacing(3),
            paddingBottom: theme.spacing(6),
            paddingInline: theme.spacing(4),
            [theme.breakpoints.up('sm')]: {
                flexGrow: 0,
                // justifyContent: 'flex-start',
                alignItems: 'flex-start',
                paddingTop: theme.spacing(6),
            },
        },
    }),
);

type Props = {
    children: ReactNode;
    className?: string;
};

function Main({ children, className }: Props) {
    const classes = useStyles();

    return (
        <Container component="main" className={c(classes.main, className)}>
            {children || (
                <div>
                    <a href="/">
                        <h1>Shelf Inspector demo</h1>
                        <h1>404</h1>
                    </a>
                </div>
            )}
        </Container>
    );
}

export default Main;
