import React, { ReactNode } from 'react';

import { makeStyles, createStyles, Grid, Card, CardContent, Typography, GridProps, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        card: {
            textAlign: 'center',
            height: '100%',
            overflowWrap: 'break-word',
            wordWrap: 'break-word',
            hyphens: 'auto',
        },
        cardContent: {
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            // justifyContent: 'space-between',
        },
        cardContentContent: {
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-evenly',
            marginTop: theme.spacing(2),
        },
    }),
);

interface Props {
    title?: string;
    children?: string | ReactNode;
    sm?: GridProps['sm'];
}

function ResultGridCard(props: Props) {
    const { title, children, sm } = props;
    const classes = useStyles();
    console.log('ResultGridCard---');

    return (
        <Grid item xs={12} sm={sm || 4}>
            <Card className={classes.card}>
                <CardContent className={classes.cardContent}>
                    {title && <Typography variant="h5">{title}</Typography>}
                    <div className={classes.cardContentContent}>{children}</div>
                </CardContent>
            </Card>
        </Grid>
    );
}

export default ResultGridCard;
