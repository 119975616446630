// import { trimTrailSlashes, trimSlashes } from '_utils';

// package.json proxy documentation
// https://webpack.js.org/configuration/dev-server/#devserver-proxy

const trimTrailSlashes = (str: string) => str.replace(/\/+$/, '');
const trimSlashes = (str: string) => str.replace(/^\/+|\/+$/, '');

const { REACT_APP_API_PATHNAME, REACT_APP_API_HOST, REACT_APP_BUILD_NAME } = process.env;

// eslint-disable-next-line no-console
console.log('REACT_APP_BUILD_NAME', REACT_APP_BUILD_NAME);

const apiHost = trimTrailSlashes(REACT_APP_API_HOST || '');
const apiPath = trimSlashes(REACT_APP_API_PATHNAME || '');

const apiUrl = `${apiHost}/${apiPath}`;

enum apiPaths {
    LOGIN = 'auth/login',
    // REGISTER = 'auth/register',
    // REFRESH_TOKEN = 'auth/refresh',
    LOGOFF = 'auth/logoff',
    // USER_PROFILE = 'auth/me',
    UPLOAD_PHOTO = 'sessions',
    WAKEUP = 'wakeup',
    // RESULTS = 'is_session_analyzed',
}

enum webRoutes {
    APP = 'app',
    RESULTS = 'results',
}

enum webPaths {
    LOGIN = '/login',
    WELCOME = '/',
    TAKE_PHOTOS = '/app',
    SELECT_PLANOGRAM = '/app/select-planogram',
    CONFIRM_PLANOGRAM = '/app/planogram-confirm',
    RESULTS = '/app/results',
    HELP = 'https://shelfdemoappstorage.blob.core.windows.net/sessions-photos/lists/manual_en.pdf',
}

const publicUrl = new URL((process as { env: { [key: string]: string } }).env.PUBLIC_URL, window.location.href);

export { apiUrl, apiPaths, webPaths, webRoutes, publicUrl };
