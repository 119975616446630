import React, { ReactNode } from 'react';
import { Card as MuiCard, CardContent, Divider, makeStyles } from '@material-ui/core';
import Text from '_components/Text';

const useStyles = makeStyles({
    title: { fontWeight: 600, fontSize: '1.25rem' },
});

type Props = {
    title: string;
    children?: ReactNode;
    className?: string;
};

function Card(props: Props) {
    const { title, children, className } = props;
    const classes = useStyles();

    return (
        <MuiCard raised elevation={25} className={className}>
            {!!title && (
                <>
                    <CardContent>
                        <Text className={classes.title} myVariant="description">
                            {title}
                        </Text>
                    </CardContent>
                    <Divider variant="middle" />
                </>
            )}
            {children}
        </MuiCard>
    );
}

export default Card;
