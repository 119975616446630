import { Reducer } from 'redux';
import { LayoutState, LayoutActionTypes } from './types';

// Type-safe initialState!
export const initialState: LayoutState = {
    theme: 'light',
    serviceWorkerRegistration: null,
    notifications: [],
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings on the reducer side,
// everything will remain type-safe.
const reducer: Reducer<LayoutState> = (state = initialState, action) => {
    switch (action.type) {
        case LayoutActionTypes.SET_THEME: {
            return { ...state, theme: action.payload };
        }
        case LayoutActionTypes.SW_UPDATE: {
            return { ...state, serviceWorkerRegistration: action.payload };
        }
        // At the moment only 1 notification with same name can be added
        case LayoutActionTypes.NOTIFICATION_ADD: {
            return state.notifications.includes(action.payload)
                ? state
                : { ...state, notifications: [...state.notifications, action.payload] };
        }
        case LayoutActionTypes.NOTIFICATION_REMOVE: {
            return { ...state, notifications: state.notifications.filter(notif => notif !== action.payload) };
        }
        case LayoutActionTypes.NOTIFICATIONS_REMOVE_ALL: {
            return { ...state, notifications: [] };
        }
        case LayoutActionTypes.SET_LANGUAGE: {
            return { ...state, language: action.payload };
        }
        default: {
            return state;
        }
    }
};

// Instead of using default export, we use named exports. That way we can group these exports
// inside the `index.js` folder.
export { reducer as layoutReducer };
