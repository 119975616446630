import { store } from '_store';
import { NOTIFICATIONS } from '_constants';
import { swRefreshApp } from '_store/layout';
import NotificationProps from './notificationProps';

const notificationsObjects: NotificationProps[] = [
    {
        id: NOTIFICATIONS.SW_INITIALIZED,
        subheader: 'notifs.swInit.subheader',
        // action: store.dispatch()
    },
    {
        id: NOTIFICATIONS.SW_UPDATED,
        title: 'notifs.swUpdate.title',
        subheader: 'notifs.swUpdate.subheader',
        closable: false,
        action: () => store.dispatch(swRefreshApp() as any),
        actionText: 'notifs.swUpdate.action',
    },
];

export default notificationsObjects;
