/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { ReactNode, useState } from 'react';

import usePortal from 'react-useportal';
import useStyles from './loadingOverlayStyles';
import Spinner from '../Spinner';

interface Props {
    children?: ReactNode;
    loading?: boolean;
    hidable?: boolean;
}

function LoadingOverlay(props: Props) {
    const { loading, children, hidable } = props;
    const classes = useStyles();
    const [overlayVisible, setOverlayVisible] = useState(true);

    const { Portal } = usePortal();

    const toggleOverlay = () => hidable && setOverlayVisible(oldOverlay => !oldOverlay);

    return loading ? (
        <Portal>
            <div className={classes.root}>
                {overlayVisible && (
                    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
                    <div className={classes.overlay} onClick={toggleOverlay} />
                )}
                <div className={classes.content}>
                    <Spinner />
                    {children}
                </div>
            </div>
        </Portal>
    ) : null;
}

export default LoadingOverlay;

/* eslint-enable jsx-a11y/click-events-have-key-events */
