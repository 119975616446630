import { apiPaths, webPaths, apiUrl, publicUrl, webRoutes } from './endpoints';
import * as phoneConstants from './phone';
import { NOTIFICATIONS } from './layout';

enum LS_KEYS {
    DEBUG = 'debug',
    EXPIRATION = 'expiration',
}

const LATEST_RESULTS_REFRESH_RATE = 3000;

// const imageExtensions = ['jpg', 'jpeg', 'png']

export {
    LATEST_RESULTS_REFRESH_RATE,
    apiUrl,
    apiPaths,
    webPaths,
    LS_KEYS,
    publicUrl,
    phoneConstants,
    NOTIFICATIONS,
    webRoutes,
};
