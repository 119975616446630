import React, { useState, useMemo } from 'react';
import { parsePhoneNumberFromString, CountryCode, getCountryCallingCode } from 'libphonenumber-js/mobile';
import {
    TextField,
    Select,
    ListSubheader,
    FormControl,
    TextFieldProps,
    FormHelperText,
    FormControlProps,
} from '@material-ui/core';
import { defaultCountry, preferredCountriesCodes, allOtherCountries } from '_constants/phone';
import { isCountryCodeType } from '_types';
import { cloneEvent } from '_utils';
import { FormattedMessage } from 'react-intl';
import useStyles from './PhoneInputStyles';
import { generateSelectOptions, renderCountrySelectValue, getProbableCountry } from './phoneInputUtils';

const preferredCountriesOptions = generateSelectOptions(preferredCountriesCodes);
const allOtherCountriesOptions = generateSelectOptions(allOtherCountries);

interface Props {
    // onChange: (value: string) => void;
    onChange: (event: any) => void;
    value: string;
    name: string;
    variant: 'outlined';
    required: boolean;
    label: string;
    error?: boolean;
    helperText: TextFieldProps['helperText'];
    onBlur: TextFieldProps['onBlur'];
    margin: FormControlProps['margin'];
    fullWidth: FormControlProps['fullWidth'];
    autoFocus?: TextFieldProps['autoFocus'];
    disabled?: TextFieldProps['disabled'];
}

function PhoneInput(props: Props) {
    const {
        fullWidth,
        margin,
        value,
        onChange,
        disabled,
        name,
        variant,
        onBlur,
        required,
        label,
        error,
        helperText,
        autoFocus,
    } = props;
    const [countryCache, setCountryCache] = useState<CountryCode>(defaultCountry);
    const classes = useStyles();

    const [extension, nationalNum, countryCode] = useMemo(() => {
        let [ext, natNumber] = value.split(' ', 2);
        const parsedNumber = parsePhoneNumberFromString(value);
        if (parsedNumber) {
            ext = `+${parsedNumber.countryCallingCode}`;
            natNumber = parsedNumber.nationalNumber.toString();
        }
        const probableCountry = getProbableCountry(parsedNumber?.countryCallingCode || ext, countryCache);
        const newCountry = parsedNumber?.country || probableCountry || countryCache;
        setCountryCache(newCountry);

        natNumber = natNumber || '';
        return [ext, natNumber, newCountry];
    }, [countryCache, value]);

    const handleCountryChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newCountry = event.target?.value;

        if (isCountryCodeType(newCountry)) {
            setCountryCache(newCountry);
            const newValue = `+${getCountryCallingCode(newCountry)} ${nationalNum}`;

            const newEvent = cloneEvent(event, newValue);
            onChange(newEvent);
        }
    };

    const handleNumberChange = (event: React.ChangeEvent<{ value: string }>) => {
        const parsedChangeVal = parsePhoneNumberFromString(event.target?.value);
        const newValue = parsedChangeVal
            ? `+${parsedChangeVal.countryCallingCode} ${parsedChangeVal.nationalNumber}`
            : `${extension || `+${getCountryCallingCode(countryCache)}`} ${event.target?.value}`;

        const newEvent = cloneEvent(event, newValue);
        onChange(newEvent);
    };

    return (
        <FormControl fullWidth={fullWidth} margin={margin} className={classes.wrap}>
            <Select
                className={classes.countrySelect}
                // select
                value={countryCode}
                disabled={disabled}
                onChange={handleCountryChange}
                variant={variant}
                renderValue={renderCountrySelectValue}
                name={name}
            >
                {preferredCountriesOptions}
                <ListSubheader className={classes.listSubheader} color="primary">
                    <FormattedMessage id="component.phone.select.allCountries" defaultMessage="All coutries" />
                </ListSubheader>
                {allOtherCountriesOptions}
            </Select>
            <TextField
                className={classes.numberInput}
                value={nationalNum}
                onChange={handleNumberChange}
                onBlur={onBlur}
                variant={variant}
                required={required}
                fullWidth={fullWidth}
                type="tel"
                label={label}
                name={name}
                autoComplete="tel"
                autoFocus={autoFocus}
                disabled={disabled}
                error={error}
            />
            <div className={classes.flexBreak} />
            {error && <FormHelperText error={error}>{helperText}</FormHelperText>}
        </FormControl>
    );
}

export default PhoneInput;
