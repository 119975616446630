import { Reducer } from 'redux';
import { ResultsState, ResultsActionTypes } from './types';

export const initialState: ResultsState = {
    sessions: {},
};

const reducer: Reducer<ResultsState> = (state = initialState, action): ResultsState => {
    switch (action.type) {
        case ResultsActionTypes.UPDATE_SESSION: {
            const { sessionId, resultData } = action.payload;
            return {
                ...state,
                sessions: {
                    ...state.sessions,
                    [sessionId]: resultData,
                },
            };
        }
        default: {
            return state;
        }
    }
};

export { reducer as resultsReducer };
