import { QueryFunctionContext } from '@tanstack/react-query';
import Axios from 'axios';
import { apiPaths, apiUrl } from '_constants';
import { ResultOk } from '_query';

export interface UploadFetchType {
    image: File | string;
    planogramId?: string;
}

const uploadPhotoFetch = async ({ image, planogramId }: UploadFetchType): Promise<ResultOk> => {
    const url = `https://shelf-demo-functions.azurewebsites.net/api/${apiPaths.UPLOAD_PHOTO}/123?type=analysis&planogram=${planogramId}`;
    const formData = new FormData();
    formData.append('forceSession', 'True');
    if (typeof image === 'string') {
        formData.append('imageInString', image);
    } else {
        formData.append('image', image);
    }
    const { data } = await Axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return data;
};

const uploadPlanogram = async ({ image }: UploadFetchType): Promise<ResultOk> => {
    const url = `https://shelf-demo-functions.azurewebsites.net/api/${apiPaths.UPLOAD_PHOTO}/123?type=reference`;
    const formData = new FormData();
    formData.append('forceSession', 'True');

    if (typeof image === 'string') {
        formData.append('imageInString', image);
    } else {
        formData.append('image', image);
    }
    const { data } = await Axios.post(url, formData, {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    });
    return data;
};

async function getResultFetch({ queryKey }: QueryFunctionContext): Promise<ResultOk> {
    const url = `${apiUrl}/${apiPaths.UPLOAD_PHOTO}/${queryKey[1]}`;
    const { data } = await Axios.get(url);
    return data;
}

export { getResultFetch, uploadPhotoFetch, uploadPlanogram };
