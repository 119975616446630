import { en, cs, de, fr } from './translations';

const getBrowserRegionCode = () => (navigator.languages && navigator.languages[0]) || navigator.language;

// Split locales with a region code
const getBrowserLanguageCode = () =>
    getBrowserRegionCode()
        .toLowerCase()
        .split(/[_-]+/)[0];

const translations = { en, cs, de, fr };

type TranslationCodes = keyof typeof translations;

const getAvailableLanguageCode = (language: string): TranslationCodes => {
    if (Object.keys(translations).includes(language)) return language as TranslationCodes;
    return 'en';
};

export { getBrowserLanguageCode, translations, getAvailableLanguageCode };
