import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { store, persistor } from '_store';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { initSentry } from './_utils/services';
// import { userWakeUpServer } from './_store/user';
import { swInitialized, swUpdated } from './_store/layout';
import { isDev } from './_utils/dev';

// Initialize Sentry
if (!isDev()) {
    initSentry();
}

ReactDOM.render(<App store={store} persistor={persistor} />, document.getElementById('root'));

// Put here actions you want to run on app start
// BackEnd wakeup (cause Azure likes to sleep)
// store.dispatch(userWakeUpServer() as any);

// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
    onSuccess: () => store.dispatch(swInitialized() as any),
    onUpdate: registration => store.dispatch(swUpdated(registration) as any),
});
// serviceWorker.unregister();
