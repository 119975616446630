import React, { ReactNode } from 'react';
import Flags from 'country-flag-icons/react/3x2';
import styles from './country-flag.module.css';

interface Props {
    country: string;
    size?: 'small';
    children?: ReactNode;
}

function CountryFlag({ country, size }: Props) {
    const ReactCountryFlag = Flags[country];
    const cls = size ? styles.small : '';
    return <ReactCountryFlag className={cls} />;
}

export default CountryFlag;
